<script>
  import { conf, permissions, modal  } from './stores.js'
  import { api, translate, i18n, setRoute, sleep, inArray, isSame, getById, genCode, anchor } from './h.js'
  import Title from './G_Title.svelte'
  import Form from './G_Form.svelte'
  import Input from './G_Input.svelte'
  import Button from './G_Button.svelte'
  import Wrong from './G_Wrong.svelte'
  import I from './G_I.svelte'
  import I18n from './G_I18n.svelte'
  import Route from './G_Route.svelte'
  import CustomerBanner from './G_CustomerBanner.svelte'

  import FilterLine from './G_FilterLine.svelte'
  import List from './G_List.svelte'

  let objs=[], obj={}, keyword="", sortBy={lastname:1}, filter={}, tagsSelected=[]

  $: list(keyword, filter, tagsSelected, sortBy)
    
  async function add(){
    setRoute("/peoples/edit")
  }

  async function bulk(){
    setRoute("/peoples/bulk")
  }

  async function edit(id){
    setRoute(`/peoples/edit/${id}`)
  }

  async function update(){
    await api(`/app/customers/peoples/set`,{obj: obj})
    list()
    getTags()
    setRoute("/peoples")
  }

  async function get(id){
    obj={}
    if(id) obj=await api(`/app/customers/peoples/get/${id}`)
    obj={ ...obj }
  }

  isSame("listPeoples","clean")
  async function list(...checkArgs){
    if(checkArgs.length && isSame("listPeoples", checkArgs)) return

    let existentTags=tagsSelected?tagsSelected.filter((t)=>{return tags.includes(t)}):false
    let req={ ...filter, ...(existentTags.length?{tags: { $in: existentTags}}:{})}
    objs = await api(`/app/customers/peoples/list`, {
      request: {...req},
      keyword:keyword,
      sortBy: sortBy
    })
  }

  async function del(){
    $modal={cancel:true, title:`${i18n("g--Delete")} ${i18n(obj.name)}`, msg:i18n("g--Confirm delete"), color:'danger', dangerZone:'yes', confirm:async ()=>{
      await api(`/app/customers/peoples/delete/${obj.id}`)
      //await sleep(100)
      list()
      setRoute("/peoples")
    }}
  }

  let peoples=[]
  getPeoples()
  async function getPeoples(){
    peoples = await api(`/app/customers/peoples/list`)
  }

  function getPeople(id){
    let ret
    ret=getById(peoples,id)
    return ret.name || '-'
  }

  let tags=[]
  getTags()
  async function getTags(){
    //tags = await api('/app/customers/peoples/tags/list')
  }

</script>


<CustomerBanner/>


<Route path="/peoples/*" pathHide="/peoples/edit/:id?" fsContent>
  <div class="container-lg fsContent">
    
    <Title title withCustomerSelector back/>

    <FilterLine {tags} bind:tagsSelected search bind:keyword 
      create={$permissions?add:false} createBulk={false}

      bind:filter filters={[
      ]}

      bind:sortBy sortBys={[
        { id:'lastname', title:'g--By lastname'},
        { id:'firstname', title:'g--By firstname'},
        { id:'organization', title:'g--By organization'},
        { id:'unit', title:'g--By unit'},
        { id:'valid', title:'g--By status', desc:true}
      ]}
    />

    <div><I18n>Peoples explanation</I18n></div>

    <List onSelect={edit} refresh={()=>list()} title="g--peoples" {objs} fields={[
      { id:'lastname', title:'g--lastname'},
      { id:'firstname', title:'g--firstname'},
      { id:'organization', title:'g--organization'},
      { id:'unit', title:'g--unit'},
      { id:'valid', title:'g--valid'}
    ]}/>
  </div>
</Route>


<Route path="/peoples/edit/:id?" let:routeParams fsContent>
  {#await get(routeParams.id) then}

    <div class="container fsContent">
      <Title title="directory" detail={obj.name} back="/peoples"/>

      <!--div class="anchor" on:click={anchor}>
        <span><I18n>Description</I18n></span>
      </div-->

      <Form fsContent norow>
        {#if obj.id}
          <div class="d-flex justify-content-end">
            <Button validity {update} class="col-auto"/>
          </div>
        {/if}
        <div class="row fsScroll">
          <Input type="hr" icon="fa-user" label="People"/>
          <div class="col-lg-9">
            <Input set="Doe" required bind:value={obj.lastname} icon type="text" label="lastname"/>
            <Input set="John" bind:value={obj.firstname} icon type="text" label="firstname"/>
          </div>

          <Input set="assets/placeholder-portrait.png" class="col-lg-3" bind:value={obj.photoCdn} icon="camera" compress compressWidth=200 type="file" label="photo" accept="image/png,image/jpeg,image/gif"/>
          <Input set="CEO" class="col-lg-12" bind:value={obj.title} icon type="textarea" rows=2 label="title"/>
          <Input set="ACMECorp" class="col-lg-6" bind:value={obj.organization} icon="fa-building" type="textarea" rows=2 label="organization"/>
          <Input set="IT" class="col-lg-6" bind:value={obj.unit} icon="fa-sitemap" type="textarea" rows=2 label="unit"/>
          
          
          <Input set="https://acme.corp" class="col-lg-6" bind:value={obj.website} icon type="url" label="website"/>
          <Input class="col-lg-6" bind:value={obj.email} required icon type="email" label="email"/>
          <Input class="col-lg-6" bind:value={obj.phoneWork} icon type="tel" label="Phone (work)"/>
          <Input class="col-lg-6" bind:value={obj.phoneHome} icon type="tel" label="Phone (home)"/>
          <Input class="col-lg-6" bind:value={obj.cell} icon type="tel" label="Phone cell"/>
          <Input class="col-lg-6" bind:value={obj.faxWork} icon type="tel" label="Fax (work)"/>
          <Input class="col-lg-6" bind:value={obj.faxHome} icon type="tel" label="Fax (home)"/>
          <Input class="col-lg-6" bind:value={obj.street} icon type="text" label="street"/>
          <Input class="col-lg-6" bind:value={obj.city} icon type="text" label="city"/>
          <Input class="col-lg-6" bind:value={obj.state} icon type="text" label="state"/>
          <Input class="col-lg-6" bind:value={obj.zipcode} icon type="text" label="zipcode"/>
          <Input class="col-lg-6" bind:value={obj.country} icon type="text" label="country"/>

          <Input class="col-lg-12" bind:value={obj.installName} icon="fa-mobile-screen-button" type="text" label="qrscreen installName" help="qrscreen installName help"/>



          <div class="row">
            {#if obj.id}
              <Button validity {del} class="col-auto"/>
              <Button validity {update} class="col-auto"/>
            {:else}
              <Button validity {update} actionModel="save" class="col-auto"/>
            {/if}
          </div>
        </div>
      </Form>
    </div>
  {:catch}
    <Wrong/>
  {/await}
</Route>