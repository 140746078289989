<script>
  import { permissions, customers, customerId, route, conf, user, menus } from './stores.js'

  import Window from './G_Window.svelte'
  import Route from './G_Route.svelte'

  import Dashboard from './L_Dashboard.svelte'
  import Models from './L_Models.svelte'
  import Peoples from './L_Peoples.svelte'
  import Entities from './L_Entities.svelte'
  import Directories from './L_Directories.svelte'

  let localConf={
    faType:"fa-light",
    faSharp:false,
    url:"https://corpincard.com",
    qrDomain:"corpincard.com",
    langs:['fr','en'],
    i18n:{},
    perms:[
      {id:"users", name:"member--manage team"}
    ],
    permsAgency:[
      {id:"customers", name:"agency--manage agency customers"},
      {id:"users", name:"agency--manage agency users"}
    ],
    account:{
      withDomains:false,
      withBilling:false,
      withApi:false,
      withDPO:false,
      withComparePlan:false,
      withLicence:true,
      withLicenceDetail:true,
      withSponsorship:true
    },
    agency:{
      withLicenceAgencyCustomer:true,
      withLicenceAgencyCustomerDetail:true,
      withSponsorship:true
    },
    cname:"customer.corpincard.com",
    

  }

  $: $menus=[
    {route: '/dashboard', name: '', icon:'home', valid:$customers},
    {route: '/homenocustomer', name: '', icon:'home', valid:!$customers},
    {route: '/models', name: 'Models', icon:'qrcode', valid:$permissions.admin},
    {route: '/peoples', name: 'Peoples', icon:'users', valid:$permissions.admin},
    {route: '/entities', name: 'Entities', icon:'building', valid:$permissions.admin},
    {route: '/directories', name: 'Directories', icon:'address-book', valid:$permissions.admin},
    {route: '/account', name: 'Account', icon:'wrench', valid:$permissions.admin || $permissions.users || $permissions.informations},
    {route: '/agency', name: 'Agency', icon:'globe', valid:$user.perms?.valid},
    {route: '/translations', name: 'Translations', icon:'university', valid:$user.perms?.translation}
  ]
</script>

<Window {localConf}>
  {#key $customerId}
    <Route path="/homenocustomer" fsContent>
      Hello home no customer
    </Route>


    <Route path="/dashboard" fsContent>
      <Dashboard/>
    </Route>

    <Route path="/models/*" fsContent>
      <Models/>
    </Route>

    <Route path="/peoples/*" fsContent>
      <Peoples/>
    </Route>

    <Route path="/directories/*" fsContent>
      <Directories/>
    </Route>

    <Route path="/entities/*" fsContent>
      <Entities/>
    </Route>
  {/key}
</Window>