<script>
  import { conf, lang  } from './stores.js'
  import { api, translate, i18n, setRoute, sleep, inArray, isSame } from './h.js'
  import Title from './G_Title.svelte'
  import Form from './G_Form.svelte'
  import Input from './G_Input.svelte'
  import Button from './G_Button.svelte'
  import Wrong from './G_Wrong.svelte'
  import I from './G_I.svelte'
  import I18n from './G_I18n.svelte'
  import Route from './G_Route.svelte'
  import CustomerBanner from './G_CustomerBanner.svelte'
  import Chart from './G_Chart.svelte'

  let dash={}
  getDash()
  $: dashboard=[
    {route: '/models', name: 'Models', icon:'qrcode', value: dash.codes || 0, color:"success", valid:true}
  ]

  let c={ 
    lightblue:'#00FFB6',
    green:'#1AD604',
    red:'#F9344F',
    purple:'#9C27B0',
    blue:'#2F9BF4',
    orange:'#FF7903'
  }

  
  async function getDash(){
    dash= await api('/app/customers/dashboard')
    if(dash) dash.ok=true
    else dash={}
  }


  /**** Processings ****/
  let chartProcessings
  $: if(dash.ok) chartProcessings={
    series: [dash.processings_compliant, dash.processings_valid - dash.processings_compliant, dash.processings - dash.processings_valid],
    labels: ['Conformes', 'Non conformes','Brouillon'],
    colors:[c.green, c.red, c.lightblue],
    chart: {
      height: 200,
      type: 'donut'
    },
    plotOptions: {
      pie: {
        donut: {
          size: 30
        }
      }
    },
    legend: {
      position: 'bottom'
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, opts) {
          return opts.w.config.series[opts.seriesIndex]
      },
    }
  }

  /**** Processors ****/
  let chartProcessors
  $: if(dash.ok) chartProcessors={
    series: [dash.processors_compliant, dash.processors_valid - dash.processors_compliant],
    labels: ['Conformes', 'Non conformes'],
    colors:[c.green, c.red],
    chart: {
      height: 200,
      type: 'donut'
    },
    plotOptions: {
      pie: {
        donut: {
          size: 30
        }
      }
    },
    legend: {
      position: 'bottom'
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, opts) {
          return opts.w.config.series[opts.seriesIndex]
      },
    }
  }


  /**** Requests ****/
  let chartRequests
  $: if(dash.ok) chartRequests={
    series: [dash.requests_new, dash.requests_check, dash.requests_inprogress, dash.requests_processed],
    labels: ['Nouvelle', 'Vérification', 'En cours', 'Traitée'],
    colors:[c.purple, c.orange, c.blue, c.green],
    chart: {
      height: 200,
      type: 'donut'
    },
    plotOptions: {
      pie: {
        donut: {
          size: 30
        }
      }
    },
    legend: {
      position: 'bottom'
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, opts) {
          return opts.w.config.series[opts.seriesIndex]
      },
    }
  }

  /**** Actions ****/
  let chartActions
  $: if(dash.ok) chartActions={
    series: [dash.actions_draft, dash.actions_planned, dash.actions_inprogress, dash.actions_closed],
    labels: [ i18n('action--Draft', $lang),  i18n('action--Planned', $lang),  i18n('action--Inprogress', $lang),  i18n('action--Closed', $lang)],
    colors:[c.lightblue, c.orange, c.blue, c.green],
    chart: {
      height: 200,
      type: 'donut'
    },
    plotOptions: {
      pie: {
        donut: {
          size: 30
        }
      }
    },
    legend: {
      position: 'bottom'
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, opts) {
          return opts.w.config.series[opts.seriesIndex]
      },
    }
  }

  /**** Breaches ****/
  let chartBreaches
  $: if(dash.ok) chartBreaches={
    series: [dash.breaches_authorityMustBeNotified, dash.breaches-dash.breaches_authorityMustBeNotified],
    labels: [ i18n('breaches--Authority must be notified', $lang),  i18n('breaches--Authority must not be notified', $lang)],
    colors:[c.red, c.green],
    chart: {
      height: 200,
      type: 'donut'
    },
    plotOptions: {
      pie: {
        donut: {
          size: 30
        }
      }
    },
    legend: {
      position: 'bottom'
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, opts) {
          return opts.w.config.series[opts.seriesIndex]
      },
    }
  }


  /**** Trainings ****/
  let chartTrainings
  $: if(dash.ok) chartTrainings={
    series: [dash.trainings_draft, dash.trainings_planned, dash.trainings_closed],
    labels: [ i18n('training--Draft', $lang),  i18n('training--Planned', $lang), i18n('training--Closed', $lang)],
    colors:[c.lightblue, c.orange, c.green],
    chart: {
      height: 200,
      type: 'donut'
    },
    plotOptions: {
      pie: {
        donut: {
          size: 30
        }
      }
    },
    legend: {
      position: 'bottom'
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, opts) {
          return opts.w.config.series[opts.seriesIndex]
      },
    }
  }

  /**** Radar ****/
  let chartRadar
  $: if(dash.ok) chartRadar={
    series: [{
      name: '%',
      data: [
        Math.round(100*dash.processings_lawfulness/dash.processings_valid) || 0,
        Math.round(100*dash.processings_recipients/dash.processings_valid) || 0,
        Math.round(100*dash.processings_security/dash.processings_valid) || 0,
        Math.round(100*dash.processings_subjects/dash.processings_valid) || 0,
        Math.round(100*dash.processings_rights/dash.processings_valid) || 0
      ],
    }],
    chart: {
      height: 300,
      type: 'radar',
      toolbar: {
        show: false//hide download
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, opts) {
          return val +"%"
      },
    },
    plotOptions: {
      radar: {
        size: 120,
        polygons: {
          strokeColors: '#e9e9e9',
          //fill: {
          //  colors: ['#f8f8f8', '#fff']
          //}
        }
      }
    },
    colors: ['#FF4560'],
    markers: {
      size: 4,
      colors: ['#fff'],
      strokeColor: '#FF4560',
      strokeWidth: 2,
    },
    tooltip: {
      enabled: false
    },
    xaxis: {
      categories: [
        i18n('Lawfulness', $lang), 
        i18n('Recipients', $lang), 
        i18n('Security', $lang), 
        i18n('Subjects', $lang),
        i18n('Rights', $lang)
      ],
    },
    yaxis: {
      max:100,
      min:0,
      tickAmount: 10,
      labels: {
        show: false,//false not works !
        formatter: function(val, i) {
          if (i % 2 === 0) {
            return '' //val +'%' //to replace show that is not working !
          } else {
            return ''
          }
        }
      }
    }
  }


  /**** dashboard tiles ****/
  function colorByPercent(percent){
    if(percent>80) return 'success'
    if(percent>50) return 'warning'
    return 'danger'
  }
</script>


<CustomerBanner/>

<Route path="/dashboard" fsContent>
  <div class="container-fluid fsContent">
    
    <Title title="Dashboard" withCustomerSelector/>

    <div class="fsScroll">
      <div class="row row-cols-1 row-cols-md-4 g-4">
        
        {#each dashboard as d}
          {#if d.valid}
            <div class="col" on:click={()=>setRoute(d.route)}>
              <div class="card h-100 pointer shadow">
                <div class="card-body">
                  <div class="card-dash">

                    {#if d.type=="right"}
                      <div>
                        <div class="text-{d.color}">{d.value}</div>
                        <div class="text">{i18n(d.name, $lang)}</div>
                      </div>
                      <div class="text-{d.color}"><I icon={d.icon}/></div>
                    {:else}
                      <div class="text-{d.color}"><I icon={d.icon}/></div>
                      <div class="text-end">
                        <div class="text-{d.color}">{d.value}</div>
                        <div class="text">{i18n(d.name, $lang)}</div>
                      </div>
                    {/if}
                  </div>
                </div>
              </div>
            </div>
          {/if}
        {/each}
      </div>

<!--
      <div class="mt-1 row row-cols-1 row-cols-md-3 row-cols-lg-4 row-cols-xxl-6 g-4">

        <div class="col" on:click={()=>setRoute('/notifications')}>
          <div class="card h-100 pointer shadow">
            <div class="card-header">Notifications</div>
            <div class="card-body">
              <div>
                <Chart data={chartNotifications}/>
              </div>
            </div>
          </div>
        </div>

        <div class="col" on:click={()=>setRoute('/tags')}>
          <div class="card h-100 pointer shadow">
            <div class="card-header">Tags</div>
            <div class="card-body">
              <div>
                <Chart data={chartTags}/>
              </div>
            </div>
          </div>
        </div>

        <div class="col" on:click={()=>setRoute('/requests')}>
          <div class="card h-100 pointer shadow">
            <div class="card-header">Demandes</div>
            <div class="card-body">
              <div>
                <Chart data={chartRequests}/>
              </div>
            </div>
          </div>
        </div>

        <div class="col" on:click={()=>setRoute('/actions')}>
          <div class="card h-100 pointer shadow">
            <div class="card-header">Actions</div>
            <div class="card-body">
              <div>
                <Chart data={chartActions}/>
              </div>
            </div>
          </div>
        </div>

        <div class="col" on:click={()=>setRoute('/trainings')}>
          <div class="card h-100 pointer shadow">
            <div class="card-header">Trainings</div>
            <div class="card-body">
              <div>
                <Chart data={chartTrainings}/>
              </div>
            </div>
          </div>
        </div>

        <div class="col" on:click={()=>setRoute('/breaches')}>
          <div class="card h-100 pointer shadow">
            <div class="card-header">Violations</div>
            <div class="card-body">
              <div>
                <Chart data={chartBreaches}/>
              </div>
            </div>
          </div>
        </div>



      </div>
      
      <div class="mt-1 row row-cols-1 row-cols-md-3 g-4">

        <div class="col">
          <div class="card h-100 pointer shadow">
            <div class="card-header">Couverture traitements</div>
            <div class="card-body">
              <div>
                <Chart data={chartRadar}/>
              </div>
            </div>
          </div>
        </div>




      </div>
    -->


    </div>
  </div>
</Route>

<style>

  .card-dash {
    line-height:2.5rem;
    font-size:3rem;
    display: flex;
    justify-content: space-between;
  }
  .text{
    line-height:1rem;
    font-size:1rem;
  }
</style>


     