<script>
  import { conf, permissions, modal  } from './stores.js'
  import { api, translate, i18n, setRoute, sleep, inArray, isSame, getById, genCode, anchor, uuid } from './h.js'
  import Title from './G_Title.svelte'
  import Form from './G_Form.svelte'
  import Input from './G_Input.svelte'
  import Inputs from './G_Inputs.svelte'
  import Button from './G_Button.svelte'
  import Wrong from './G_Wrong.svelte'
  import I from './G_I.svelte'
  import I18n from './G_I18n.svelte'
  import Route from './G_Route.svelte'
  import CustomerBanner from './G_CustomerBanner.svelte'

  import FilterLine from './G_FilterLine.svelte'
  import List from './G_List.svelte'

  let objs=[], obj={}, keyword="", sortBy={name:1}, filter={}, tagsSelected=[]

  $: list(keyword, filter, tagsSelected, sortBy)
    
  async function add(){
    setRoute("/directories/edit")
  }

  async function bulk(){
    setRoute("/directories/bulk")
  }

  async function edit(id){
    setRoute(`/directories/edit/${id}`)
  }

  async function update(){
    await api(`/app/customers/directories/set`,{obj: obj})
    list()
    getTags()
    setRoute("/directories")
  }

  async function get(id){
    obj={}
    if(id) obj=await api(`/app/customers/directories/get/${id}`)
    obj={ ...obj }
  }

  isSame("listDirectories","clean")
  async function list(...checkArgs){
    if(checkArgs.length && isSame("listDirectories", checkArgs)) return

    let existentTags=tagsSelected?tagsSelected.filter((t)=>{return tags.includes(t)}):false
    let req={ ...filter, ...(existentTags.length?{tags: { $in: existentTags}}:{})}
    objs = await api(`/app/customers/directories/list`, {
      request: {...req},
      keyword:keyword,
      sortBy: sortBy
    })
  }

  async function del(){
    $modal={cancel:true, title:`${i18n("g--Delete")} ${i18n(obj.name)}`, msg:i18n("g--Confirm delete"), color:'danger', dangerZone:'yes', confirm:async ()=>{
      await api(`/app/customers/directories/delete/${obj.id}`)
      list()
      setRoute("/directories")
    }}
  }

  let entities=[]
  getEntities()
  async function getEntities(){
    entities = await api(`/app/customers/entities/list`)
  }

  function getEntity(id){
    let ret
    ret=getById(entities,id)
    return ret.name || '-'
  }

  let tags=[]
  getTags()
  async function getTags(){
    //tags = await api('/app/customers/directories/tags/list')
  }

  async function test(){
    let ret=await api('/app/customers/directories/test', {obj: obj})
    $modal={
      title:"test", 
      msg:ret.cn || i18n("directory--entry not found"),
      color:ret.cn?'success':'danger',
      ok:async ()=>{}
    }
  }
  async function stop(){
    await api('/app/customers/directories/stop', {obj: obj})
  }
</script>


<CustomerBanner/>


<Route path="/directories/*" pathHide="/directories/edit/:id?" fsContent>
  <div class="container-lg fsContent">
    
    <Title title withCustomerSelector back/>

    <FilterLine {tags} bind:tagsSelected search bind:keyword 
      create={$permissions?add:false} createBulk={false}

      bind:filter filters={[
      ]}

      bind:sortBy sortBys={[
        { id:'name', title:'g--By name'},
        { id:'status', title:'g--By status', desc:true}
      ]}
    />

    <List onSelect={edit} refresh={()=>list()} title="g--directories" {objs} fields={[
      {id:"name", title:"g--name"},
      {id:"valid", title:"g--valid"}
    ]}/>
  </div>
</Route>


<Route path="/directories/edit/:id?" let:routeParams fsContent>
  {#await get(routeParams.id) then}

    <div class="container fsContent">
      <Title title="directory" detail={obj.name} back="/directories"/>

      <Form fsContent norow>
        {#if obj.id}
          <div class="d-flex justify-content-end">
            <Button validity {update} class="col-auto"/>
          </div>
        {/if}
        <div class="row fsScroll">
          <Input type="hr" icon="fa-plug" label="Connection"/>
          <!--Input bind:value={obj.entity} type="select" required icon="fa-building" label="entity" options={entities}/-->
          <Input bind:value={obj.name} required type="text" icon="fa-hashtag" label="Name"/>
          <Input bind:value={obj.url} required type="url" icon label="Url"/>
          <Input bind:value={obj.dn} type="text" icon="user" label="Dn"/>
          <Input bind:value={obj.pwd} type="password" icon label="Password"/>
          <Input bind:value={obj.base} type="text" icon="fa-house-blank" label="Base"/>
          <Input bind:value={obj.testEmail} class="col-lg-10" type="text" icon="fa-envelope" label="Test email"/>
          <Button label=" " validity {test} class="col-lg-2"/>

          <Input class="col-lg-2" lg set={false} bind:value={obj.isAgent} type="switch" label="CorpInCard agent" option="yes"/>
 
          {#if obj.isAgent}
            <Input set={uuid()} bind:value={obj.agentKey} type="text" required icon="fa-key" label="Agent key"/>
          {/if}

          <Input type="hr" icon="fa-sliders" label="Mapping attributes"/>
          <Input set="cn" bind:value={obj.attr_fullname} required type="text" label="Full name" help="John Doe"/>
          <Input set="givenName" bind:value={obj.attr_firstname} required type="text" label="Firstname"/>
          <Input set="sn" bind:value={obj.attr_lastname} required type="text" label="Lastname"/>
          
          <Input set="company" bind:value={obj.attr_organizatio} required type="text" label="Company"/>
          <Input set="departmentNumber" bind:value={obj.attr_unit} required type="text" label="Department"/>
          <Input set="facsimileTelephoneNumber" bind:value={obj.attr_faxWork} required type="text" label="Work Fax"/>
          <Input set="pager" bind:value={obj.attr_cell} required type="text" label="Work Cell"/>
          <Input set="l" bind:value={obj.attr_city} required type="text" label="City"/>
          <Input set="mail" bind:value={obj.attr_email} required type="text" label="Mail"/>
          <Input set="physicalDeliveryOfficeName" bind:value={obj.attr_location} required type="text" label="Location"/>
          <!--Input set="postOfficeBox" bind:value={obj.attr_pobox} required type="text" label="Po Box"/-->
          <Input set="postalCode" bind:value={obj.attr_zip} required type="text" label="Zipcode"/>
          <Input set="st" bind:value={obj.attr_state} required type="text" label="State"/>
          <Input set="street" bind:value={obj.attr_street} required type="text" label="Street"/>
          <Input set="telephoneNumber" bind:value={obj.attr_phoneWork} required type="text" label="Work phone"/>
          <Input set="title" bind:value={obj.attr_title} required type="text" label="Job title"/>
          <Input set="thumbnailPhoto" bind:value={obj.attr_photo} required type="text" label="Photo"/>
          

          <div class="row">
            <!--Button validity action={stop} actionModel="delete" title="Stop" class="col-auto"/-->
            {#if obj.id}
              <Button validity {del} class="col-auto"/>
              <Button validity {update} class="col-auto"/>
            {:else}
              <Button validity {update} actionModel="save" class="col-auto"/>
            {/if}
          </div>
        </div>
      </Form>
    </div>
  {:catch}
    <Wrong/>
  {/await}
</Route>